<template>
  <a-layout id="components-layout-demo-fixed">
    <Navibar />
    <a-layout-content :style="{ padding: '0 50px', marginTop: '50px' }">
      <Breadcrumb :item="['Home']"/>
      <div :style="{ background: '#fff', padding: '24px', minHeight: '500px' }">
        <a-alert type="error"
                 show-icon>
          <div slot="message">找不到该页面</div>
          <div slot="description">
            404 Not Found...
          </div>
        </a-alert>
      </div>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center' }">
      Powered by Vue + Antdv
    </a-layout-footer>
  </a-layout>
</template>

<script>
import Navibar from "@/components/Navibar";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "NotFoundPage",
  components: {Breadcrumb, Navibar},
}
</script>

<style scoped>

</style>
